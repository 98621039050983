export const state = () => ({
  formType: null,
  loading: false,
  uuid: null,
  appImage: null,
  selectedStatus: null,
  buttonName: null,
  externalUrl: null,
  flutterUrl: null,
  sequenceNumber: null,
  translations: [],
  title: null,
  description: null,
  bannerType: null,
  isTakeover: null,
});

export const getters = {
  formData(state) {
    return {
      uuid: state.uuid,
      app_image_uuid: state.appImage ? state.appImage.uuid : null,
      translations: [{ title: state.title, description: state.description, locale: 'id' }],
      external_url: state.externalUrl,
      flutter_url: state.flutterUrl,
      button_name: state.buttonName,
      sequence_number: state.sequenceNumber,
      availability_status_id: state.selectedStatus ? state.selectedStatus.id : null,
      type_id: state.bannerType ? state.bannerType.id : null,
      is_takeover: state.isTakeover ? state.isTakeover.id : null,
    };
  },
};

export const mutations = {
  SET_FORM_TYPE(state, val) {
    state.formType = val;
  },
  SET_LOADING(state, val) {
    state.loading = val;
  },
  SET_UUID(state, val) {
    state.uuid = val;
  },
  SET_APP_IMAGE(state, val) {
    state.appImage = val;
  },
  SET_SELECTED_STATUS(state, val) {
    state.selectedStatus = val;
  },
  SET_BUTTON_NAME(state, val) {
    state.buttonName = val;
  },
  SET_EXTERNAL_URL(state, val) {
    state.externalUrl = val;
  },
  SET_FLUTTER_URL(state, val) {
    state.flutterUrl = val;
  },
  SET_SEQUENCE_NUMBER(state, val) {
    state.sequenceNumber = val;
  },
  SET_TRANSLATIONS(state, val) {
    state.translations = val;
  },
  SET_TITLE(state, val) {
    state.title = val;
  },
  SET_DESCRIPTION(state, val) {
    state.description = val;
  },
  ADD_APP_IMAGE(state, payload) {
    state.appImage = payload;
  },
  // eslint-disable-next-line no-unused-vars
  REMOVE_APP_IMAGE(state, arrIndex) {
    state.appImage = null;
  },
  SET_BANNER_TYPE(state, val) {
    state.bannerType = val;
  },
  SET_IS_TAKEOVER(state, val) {
    state.isTakeover = val;
  },
};

export const actions = {
  postData: async function ({ commit, getters, rootState }) {
    try {
      commit('SET_LOADING', true);
      let formData = getters.formData;
      const url = '/api/banners/save';
      formData.url = rootState.route.path.substring(1);

      const {
        data: { data: responseData },
      } = await this._vm.$http.post(url, formData);
      if (responseData.uuid) {
        commit('SET_UUID', responseData.uuid);
      }
      return true;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      // commit('RESTORE_DEFAULT_IS_PUBLISHED');
      throw e;
    } finally {
      commit('SET_LOADING', false);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async applyBaseData({ commit }, data) {},
  async applyEditData({ commit, rootState }, data) {
    commit('SET_UUID', data.uuid);
    if (data.app_image) {
      data.app_image.src = data.app_image.photo_image;
    }
    commit('SET_APP_IMAGE', data.app_image);
    commit('SET_BUTTON_NAME', data.button_name);
    commit('SET_EXTERNAL_URL', data.external_url);
    commit('SET_FLUTTER_URL', data.flutter_url);
    commit('SET_SEQUENCE_NUMBER', data.sequence_number);
    commit('SET_TRANSLATIONS', data.translations);
    if (data.translations) {
      commit('SET_TITLE', data.translations[0].title);
      commit('SET_DESCRIPTION', data.translations[0].description);
    }

    if (data.availability_status_id) {
      const selected = rootState.global.availabilityStatuses.find((item) => {
        return item.id == data.availability_status_id;
      });
      commit('SET_SELECTED_STATUS', selected ? selected : null);
    }

    if (data.type_id) {
      const selected = rootState.global.bannerTypes.find((item) => {
        return item.id == data.type_id;
      });
      commit('SET_BANNER_TYPE', selected ? selected : null);
    }

    if (data.is_takeover !== null && data.is_takeover !== undefined) {
      const selected = rootState.global.isTakeoverList.find((item) => {
        return item.id === data.is_takeover;
      });
      commit('SET_IS_TAKEOVER', selected ? selected : null);
    }
  },
  async fetchBaseData({ commit, dispatch, state, rootState }, params) {
    try {
      const form = state.formType;
      console.log('fetch base', params);

      if (form === 'ADD') {
        commit('SET_LOADING', true);
        // const {
        //   data: { data: resultData },
        // } = await this._vm.$http.get('/api/banners/add', {
        //   params: {
        //     url: rootState.route.path.substring(1),
        //   },
        // });
        // await dispatch('applyBaseData', resultData);
      } else {
        commit('SET_LOADING', true);
        const {
          data: { data: resultData },
        } = await this._vm.$http.get('/api/banners/edit/' + state.uuid, {
          params: {
            url: rootState.route.path.substring(1),
          },
        });
        await dispatch('applyEditData', resultData.edit_data);
      }
    } finally {
      commit('SET_LOADING', false);
    }
  },
  restoreInitialState({ commit }) {
    commit('SET_UUID', null);
    commit('SET_APP_IMAGE', null);
    commit('SET_SELECTED_STATUS', null);
    commit('SET_BUTTON_NAME', null);
    commit('SET_EXTERNAL_URL', null);
    commit('SET_FLUTTER_URL', null);
    commit('SET_SEQUENCE_NUMBER', null);
    commit('SET_TRANSLATIONS', []);
    commit('SET_TITLE', null);
    commit('SET_DESCRIPTION', null);
    commit('SET_IS_TAKEOVER', null);
  },
};
